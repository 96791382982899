<script>
import Navbar from './Navbar.vue';
import Footer from './Footer.vue'
import Form from './Form.vue';
export default {
    components: { Navbar, Footer, Form }
  }
</script>

<template>
  <Navbar />
  <Form />
  <Footer />
</template>