<script >
import Navbar from '@/components/register/Navbar.vue';
import Footer from '@/components/register/Footer.vue'
import PostList from '@/components/register/PostList.vue';
export default {
components: { Navbar, Footer, PostList }
}
</script>

<template>
  <Navbar />
  <PostList />
  <Footer />
</template>