<template>
  <v-card elevation="0">
    <v-tabs v-model="tab" align-tabs="center" background-color="white" color="#343F53" slider-color="#343F53" grow>
      <v-tab value="1" class="button biografia">
        <img src="@/assets/images/icons/book.png" alt="" class="tab-icon">
        <p class="tab">Biografia</p>
      </v-tab>
      <v-tab value="2" class="button mensagens">
        <img src="@/assets/images/icons/chat.png" alt="" class="tab-icon">
        <p class="tab">Mensagens</p>
      </v-tab>
      <v-tab value="3" class="button velorio">
        <img src="@/assets/images/icons/virtual.png" alt="" class="tab-icon">
        <p class="tab">Velório virtual</p>
      </v-tab>
      <v-tab value="4" class="button servicos">
        <img src="@/assets/images/icons/servicos.png" alt="" class="tab-icon">
        <p class="tab">Serviços</p>
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="1">
          <v-container fluid>
            <v-row>
              <v-col>
                <h2 class="title">Biografia</h2>
                <p class="msgobtuario">{{ obituary.text }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>

        <v-tabs-window-item value="2">
          <v-container fluid>
            <v-row>
              <v-col>
                <h2 class="title">Mensagens</h2>
                <v-timeline align="start">
                  <v-timeline-item v-for="(item, i) in items" :key="i" :dot-color="item.color" fill-dot>
                    <v-card class="timeline-card">
                      <div v-if="item.image" class="timeline-image">
                        <img :src="item.image" alt="" class="image">
                      </div>
                      <div v-if="item.image" class="timeline-divider"></div>
                      <v-card-text class="timeline-content">
                        <v-card-title class="timeline-title">
                          {{ item.title }}
                        </v-card-title>
                        <v-card-text class="timeline-text">
                          {{ item.text }}
                          <v-card-text v-if="item.usuario && item.data">
                            {{ item.usuario }} - {{ item.data }}
                          </v-card-text>
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>

        <v-tabs-window-item value="3">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" md="8">
                <h2 class="title">Velório Virtual</h2>
                <v-card class="video-card" elevation="2">
                  <v-responsive :aspect-ratio="16 / 9">
                    <iframe :src="'https://www.youtube.com/embed/' + aovivo.link" frameborder="0" allowfullscreen
                      class="video-frame" style="width: 100%; height: 100%;"></iframe>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>


        <v-tabs-window-item value="4">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="8">
                <h2 class="title">Serviços</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Velório</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> {{ servico.velorioInfo
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Sepultamento</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> {{
                    servico.seputamentoInfo }}
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/info.svg" alt=""></v-icon> Jazigo: {{
                    servico.jazigoInfo }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">A Flor do Café - Floricultura - Campo Santo Parque das Flores</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> Campo Santo Parque das
                  Flores, Maceió - AL
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/book.svg" alt=""></v-icon> Catálogo:
                  <a href="https://catalogo.aflordocafe.com.br" target="_blank">
                    https://catalogo.aflordocafe.com.br
                  </a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/phone.svg" alt=""></v-icon> Telefone:
                  <a href="tel:+558233715258">(82) 3371-5258</a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/whatsapp.svg" alt=""></v-icon> WhatsApp:
                  <a href="tel:+5582999692001">(82) 99969-2001</a>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="subtitle">Memorial Flores - Floricultura - Memorial Parque Maceió</h3>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/location.svg" alt=""></v-icon> Cemitério Memorial
                  Parque Maceió, Maceió - AL
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/book.svg" alt=""></v-icon> Catálogo:
                  <a href="https://memorialflores.com.br" target="_blank">
                    https://memorialflores.com.br
                  </a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/phone.svg" alt=""></v-icon> Telefone:
                  <a href="tel:+558233715258">(82) 3371-5258</a>
                </p>
                <p class="info">
                  <v-icon left><img src="@/assets/images/icons/whatsapp.svg" alt=""></v-icon> WhatsApp:
                  <a href="tel:+5582999692001">(82) 99969-2001</a>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>

      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<style scoped>
*,
*::before,
*::after {
  font-family: "Sora", sans-serif;
}

.v-card {
  margin: 2rem auto;
  max-width: 1000px;
}

.tab-icon {
  width: 22px;
  margin-right: 0.5rem;
}

.title {
  color: #343F53;
  font-size: 2.6rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-bottom: 0.1rem;
}

.title::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #343F53;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tab {
  color: #343F53;
  font-size: 1.2rem;
  letter-spacing: 0;
}

.msgobtuario {
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343F53;
  font-size: 1.2rem;
}

.v-tabs .v-tab {
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0 2rem;
  margin-left: 0.4rem;
  border: 1px solid #343F53;
}

.button:hover {
  background-color: #343F53;
}

.button:hover p {
  color: #fff;
}

.biografia:hover img {
  content: url("@/assets/images/icons/book_white.png");
}

.mensagens:hover img {
  content: url("@/assets/images/icons/chat2_white.svg");
}

.velorio:hover img {
  content: url("@/assets/images/icons/virtual_white.png");
}

.servicos:hover img {
  content: url("@/assets/images/icons/servicos_white.png");
}

.timeline-card {
  border: 1px solid #343F53;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 100%;
}

.timeline-image {
  padding: 1rem;
}

.timeline-image .image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.timeline-divider {
  height: 1px;
  background-color: #343F53;
}

.timeline-content {
  padding: 1rem;
}

.timeline-title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.timeline-text {
  color: #343F53;
}

.video-card {
  border-radius: 12px;
  overflow: hidden;
}

.video-frame {
  border-radius: 12px;
}

.subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #343F53;
}

.info {
  font-size: 1rem;
  font-weight: 400;
  color: #343F53;
  margin-bottom: 0.4rem;
}

a {
  color: #343F53;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>

<script>
export default {
  data: () => ({
    tab: "1",
    obituary: {
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum eveniet blanditiis beatae ducimus sit! Ipsum, sed, tempore facere velit, nihil provident molestiae quisquam dolores ratione sequi ab tempora voluptatibus illo. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum eveniet blanditiis beatae ducimus sit! Ipsum, sed, tempore facere velit, nihil provident molestiae quisquam dolores ratione sequi ab tempora voluptatibus illo. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum eveniet blanditiis beatae ducimus sit! Ipsum, sed, tempore facere velit, nihil provident molestiae quisquam dolores ratione sequi ab tempora voluptatibus illo. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum eveniet blanditiis beatae ducimus sit! Ipsum, sed, tempore facere velit, nihil provident molestiae quisquam dolores ratione sequi ab tempora voluptatibus illo.",
    },
    items: [
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Maycon Henrique",
        data: '15/08/2024',
        image: 'https://images.unsplash.com/photo-1456659122552-6ee1788174bb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Rostand Henrique",
        data: '15/08/2023'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Victor Henrique",
        data: '15/08/2022'
      },
      {
        color: '#343F53',
        title: 'Lorem Ipsum Dolor',
        text: 'Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at.',
        usuario: "Nelson Henrique",
        data: '15/08/2021',
        image: 'https://images.unsplash.com/photo-1494859780588-cf6063220793?q=80&w=1985&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
    ],
    aovivo: {
      link: 'Na0w3Mz46GA'
    },
    servico: {
      velorioInfo: "Cemitério Parque das Flores - 14:00",
      seputamentoInfo: "Cemitério Parque das Flores - 15:00",
      jazigoInfo: "Rosas E 013"

    }
  }),
}
</script>
