<template>
  <section>
    <h1>Com o Parque das Recordações, você pode:</h1>
    <div class="imagem">
      <img src="../../assets/images/Elementos/icons.png" alt="">
    </div>
    
  </section>

</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
h1 {
  padding: 4rem 0rem;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  color: #343F53;
}
.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}
</style>