<template>
  <main>
    <div class="container">
      <div class="login">
        <h1>Administrador</h1>
        <form action="">
          <div class="form-group">
            <label for="text">Login:</label>
            <input type="text" id="text">
          </div>
          <div class="form-group">
            <label for="password">Senha:</label>
            <input type="password" id="password">
          </div>
          <a class="reset" href="#">Esqueci minha senha</a>
          <router-link to="/admin/register"><button type="submit">Entrar</button></router-link>
        </form>
      </div>
    </div>
  </main>
</template>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}

main {
  background-image: url('../../assets/images/Elementos/fundo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.container {
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.text {
  color: #FFFFFF;
}

.crie {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}

.text p {
  font-size: 31px;
  font-weight: 300;
}

.text h1 {
  font-size: 86px;
  font-weight: 800;
  line-height: 5rem;
  text-align: center;
}

.login {
  display: flex;
  flex-direction: column;
  background-color: #343F53;
  padding: 1rem 2.6rem;
  border-radius: 9px;
  color: #FFFFFF;
  align-items: center;
  text-align: center;
}

.login h1 {
  min-width: 19rem;
  font-size: 37px;
  font-weight: 800;
}

.login input {
  margin-top: 0.8rem;
  border: none;
  padding: 0.2rem;
  border-radius: 6px;
  min-width: 14em;
  background-color: #fff;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-group label {
  margin-right: 0.5rem;
  margin-top: 0.8rem;
  min-width: 5.5rem;
  font-size: 25px;
  font-weight: 300;
}

.reset {
  margin-top: 0.8rem;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 100;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login button {
  margin-top: 0.8rem;
  padding: 0.2rem 0;
  color: #343F53;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  width: 80%;
  background-color: #fff;
}

.login button:hover,
.login a:hover {
  opacity: 0.5;
}

@media (max-width: 1037px) {
  .container {
    flex-direction: column;
  }

  .text h1 {
    font-size: 50px;
    line-height: 2.5rem;
  }

  .login {
    padding: 1rem 1.5rem;
  }
}
</style>
