<template>
  <v-card class="container">
    <v-stepper color="red" prev-text="Voltar" next-text="Próximo" :items="['Falecido', 'Moderador', 'Despedida']">
      <template v-slot:item.1>
        <v-card flat>
          <v-container>
            <v-card class="mx-auto" max-width="800" elevation="1">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Falecido
                </v-card-title>
              </v-toolbar>
              <v-card-text class="title">
                <v-btn text color="indigo-darken-3" @click="dialogFalecido = true">Buscar</v-btn>
              </v-card-text>

              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="falecido.firstname" :counter="100" :rules="nameRules" label="Nome"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthdate" :rules="dateRules" label="Data de Nascimento"
                        hide-details required type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.deathdate" :rules="dateRules" label="Data de Falecimento"
                        hide-details type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="falecido.location" :counter="100" label="Localização" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="falecido.birthCity" :rules="cityRules" label="Cidade de Nascimento"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select v-model="falecido.birthState" :items="states" label="Estado de Nascimento" hide-details
                        required class="custom-text-field"></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select v-model="falecido.birthCountry" :items="countries" label="País de Nascimento"
                        hide-details required class="custom-text-field"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="falecido.cpf" :rules="cpfRules" label="CPF" hide-details @input="maskCPF"
                        required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input v-model="falecido.profilePicture" label="Foto de Perfil" hide-details
                        accept="image/*" class="custom-file-input"></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-dialog v-model="dialogFalecido" max-width="800">
              <v-card>
                <v-card-title>Pesquisar por CPF</v-card-title>
                <v-card-text>
                  <v-card class="search" elevation="0">
                    <v-text-field v-model="cpf" label="CPF" hide-details @input="maskCPF"></v-text-field>
                    <v-btn color="indigo-darken-2" @click="searchCPF('falecido')">Pesquisar</v-btn>
                  </v-card>

                  <v-data-table :headers="headers" :items="cpfResults">
                    <template #item.action="{ item }">
                      <v-btn color="green" @click="selectItem(item, 'falecido')">Adicionar</v-btn>
                    </template>
                    <template #item.data_nascimento="{ item }">
                      {{ formatDate(item.data_nascimento) }}
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="dialogFalecido = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="800" elevation="2">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Moderador
                </v-card-title>
              </v-toolbar>

              <!--  <v-card-text class="title">
                <v-btn text color="indigo-darken-3" @click="dialogModerador = true">Buscar</v-btn>
              </v-card-text>-->
              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.nome" :rules="nameRules" label="Nome" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="moderador.phone" :rules="phoneRules" label="Telefone do Moderador"
                        hide-details @input="maskPhone(moderador)" required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.email" :rules="emailRules" label="E-mail do Moderador"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="moderador.password" :rules="passwordRules" label="Senha do Moderador"
                        hide-details type="password" required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="moderador.cliente" label="Moderador é cliente?"
                        :items="['Sim, cliente do Grupo', 'Ainda não']"></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="moderador.parentesco" label="Parentesco com falecido?"
                        :items="['Não informado', 'Sim', 'Não']"></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-dialog v-model="dialogModerador" max-width="800">
              <v-card>
                <v-card-text>
                  <v-card class="search" elevation="0">
                    <v-text-field v-model="cpf" label="CPF" hide-details @input="maskCPF"></v-text-field>
                    <v-btn color="indigo-darken-2" @click="searchCPF('moderador')">Pesquisar</v-btn>
                  </v-card>

                  <v-data-table :headers="headers" :items="cpfResults">
                    <template #item.action="{ item }">
                      <v-btn color="green" @click="selectItem(item, 'moderador')">Adicionar</v-btn>
                    </template>
                    <template #item.data_nascimento="{ item }">
                      {{ formatDate(item.data_nascimento) }}
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="dialogModerador = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="800" elevation="2">
              <v-toolbar color="primary">
                <v-card-title class="headline font-weight-bold" style="padding: 16px;">
                  Cadastrar Despedida
                </v-card-title>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyPost" label="Privacidade"
                        :items="['Publica', 'Privada']"></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyComments" label="Comentários Públicos"
                        :items="['Sim', 'Não']"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input v-model="despedida.banner" label="Foto de Perfil" hide-details accept="image/*"
                        class="custom-file-input"></v-file-input>
                    </v-col>

                    <div class="text-center pa-4">
                      <v-btn color="green" @click="dialogQRCODE = true">
                        Finalizar
                      </v-btn>
                      <v-dialog v-model="dialogQRCODE" width="auto">
                        <v-card max-width="400" prepend-icon="mdi-update"
                          title="Cadatro finalizado com sucesso!"
                          text="QRcode para acessar:">
                          <div class="qrcode">
                            <qrcode-vue :value="value" :size="size" level="H" />
                          </div>
                          
                          <template v-slot:actions>
                            <v-btn class="ms-auto" text="Ok" @click="dialogQRCODE = false"></v-btn>
                          </template>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card>
      </template>
    </v-stepper>


  </v-card>

</template>

<script>
import QrcodeVue from 'qrcode.vue'
import axios from 'axios';
export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      dialogQRCODE: false,
      value: '123123211',
      size: 150,
      valid: false,
      dialogFalecido: false,
      dialogModerador: false,
      cpf: '',
      cpfResults: [],
      falecido: {
        firstname: '',
        birthdate: '',
        deathdate: '',
        birthCity: '',
        birthState: '',
        birthCountry: '',
        profilePicture: '',
        cpf: '',
        location: ''
      },
      moderador: {
        nome: '',
        phone: '',
        email: '',
        password: '',
        cliente: '',
        parentesco: ''

      },
      despedida: {
        banner: '',
        privacyPost: '',
        privacyComments: ''
      },
      headers: [
        { text: 'Nome', value: 'nome', title: 'Nome' },
        { text: 'Sobrenome', value: 'sobrenome', title: 'Sobrenome' },
        { text: 'Data de Nascimento', value: 'data_nascimento', title: 'Data de Nascimento' },
        { text: 'CPF', value: 'cpf', title: 'CPF' },
        { text: 'Ações', value: 'action', title: 'Ações', sortable: false }
      ],
      nameRules: [(v) => !!v || 'Nome é obrigatório'],
      dateRules: [(v) => !!v || 'Data é obrigatória'],
      cityRules: [(v) => !!v || 'Cidade é obrigatória'],
      emailRules: [
        (v) => !!v || 'E-mail é obrigatório',
        (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido'
      ],
      passwordRules: [(v) => !!v || 'Senha é obrigatória'],
      phoneRules: [(v) => !!v || 'Telefone é obrigatório'],
      states: ['SP', 'RJ', 'MG', 'RS', 'BA'],
      countries: ['Brasil', 'Argentina', 'Estados Unidos']
    };
  },
  methods: {
    maskCPF() {
      this.falecido.cpf = this.applyCPFMask(this.falecido.cpf);
      this.cpf = this.applyCPFMask(this.cpf);
    },
    applyCPFMask(cpf) {
      cpf = cpf.replace(/\D/g, '');
      if (cpf.length > 11) cpf = cpf.slice(0, 11);
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    maskPhone(moderador) {
      moderador.phone = moderador.phone.replace(/\D/g, '');
      if (moderador.phone.length > 2) {
        moderador.phone = '(' + moderador.phone.slice(0, 2) + ') ' + moderador.phone.slice(2);
      }
      if (moderador.phone.length > 9) {
        moderador.phone = moderador.phone.slice(0, 10) + '-' + moderador.phone.slice(10);
      }
      if (moderador.phone.length > 15) {
        moderador.phone = moderador.phone.slice(0, 15);
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    searchCPF(itemType) {
      this.cleanDataBeforeSearch();
      axios.get("https://maestrix.grupoparque.com.br:8443/api-recordacao/cliente/pessoas", {
        params: {
          tipo: itemType,
          cpf: this.cpf
        }
      })
        .then(response => {
          this.cpfResults = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar CPF:", error);
        });
    },
    selectItem(item, itemType) {
      if (itemType === 'falecido') {
        this.falecido.firstname = item.nome;
        this.falecido.birthdate = item.data_nascimento;
        this.falecido.deathdate = item.data_falecimento;
        this.falecido.cpf = item.cpf;
      } else if (itemType === 'moderador') {
        this.moderador.email = item.email || '';
        this.moderador.phone = item.telefone || '';
      }
      if (itemType === 'falecido') {
        this.dialogFalecido = false;
      } else if (itemType === 'moderador') {
        this.dialogModerador = false;
      }
    }, removeMask(value) {
      return value.replace(/\D/g, '');
    }, cleanDataBeforeSearch() {
      this.cpf = this.removeMask(this.cpf);
    }
  }
};
</script>

<style scoped>
.container {
  min-height: 63vh;
}

.custom-text-field {
  margin-bottom: 1rem;
}

.custom-file-input {
  margin-top: 1rem;
}

.search {
  margin-bottom: 1rem;
}

.finish {
  margin: 1rem;
}
.qrcode {
  text-align: center;
}
</style>
