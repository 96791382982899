import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components,
  directives,
})
import Home from "./views/Home.vue";
import Admin from "./views/Admin.vue";
import Register from "./views/Register.vue";
import Create from './components/register/Create.vue'
import Homenagem from "./views/Homenagem.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/post", component: Homenagem },
    { path: "/admin", component: Admin },
    { path: "/admin/register", component: Register },
    { path: "/admin/create", component: Create }
  ],
});

const app = createApp(App)
 
// Desativa o aviso de multi-word component name
app.config.warnComponentName = false;
 
app.use(vuetify).use(router).mount("#app");