<script >
  import Navbar from '@/components/home/Navbar.vue'
  import Banner from '@/components/homenagem/Banner.vue'
  import Informacoes from '@/components/homenagem/Informacoes.vue'
  import BotoesAbas from '@/components/homenagem/BotoesAbas.vue'
  import Footer from '@/components/register/Footer.vue'
  export default {
  components: { Navbar, Banner, Informacoes, BotoesAbas, Footer }
}
</script>

<template>
    <Navbar />
    <Banner />
    <Informacoes />
    <BotoesAbas />
    <Footer />
</template>