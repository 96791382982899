<template>
  <section>
    <div>
      <img src="@/assets/images/Elementos/memorial.png" alt="">
    </div>
  </section>
</template>

<style scoped>
  section {
    height: 30vh;
    background-image: url('@/assets/images/Elementos/fundo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  
  section div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  section div img {
    border-radius: 100%;
    width: 260px;
    position: absolute;
    bottom: 0; 
    transform: translateY(50%); 
  }
</style>
