<template class="main">
  <v-container class="container">
    <v-card class="mx-auto table-pai" max-width="800" elevation="4">
      <v-card-title class="headline font-weight-bold" style="padding: 16px;">Gerenciar Despedidas</v-card-title>
      <v-card-text>
        <v-data-table-server
          v-model:items-per-page="itemsPerPage"
          :headers="headers"
          :items="serverItems"
          :items-length="totalItems"
          :loading="loading"
          :search="search"
          item-value="cod"
          @update:options="loadItems"
          class="elevation-0 teste"
        >
          <template #item.action="{ item }" >
            <router-link :to="`/admin/${item.cod}`">
              <v-btn type="submit" color="primary">
                Gerenciar
              </v-btn>
            </router-link>
          </template>
          
        </v-data-table-server>
        <v-col cols="12" class="text-left">
          <router-link to="/admin/create">
            <v-btn type="submit" color="primary">
              Nova postagem
            </v-btn>
          </router-link>
        </v-col>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
const posts = [
  {
    cod: "475c55e8",
    name: "Logan",
    falecimento: "2014-10-14"
  },
  {
    cod: "5e4f1c33",
    name: "Sarah",
    falecimento: "2020-09-20"
  },
  {
    cod: "a61fcff8",
    name: "Michael",
    falecimento: "2019-03-14"
  },
  {
    cod: "3a644d8a",
    name: "Christopher",
    falecimento: "2022-09-19"
  },
  {
    cod: "bfbdb163",
    name: "Mitchell",
    falecimento: "2020-01-02"
  },
  {
    cod: "cffc0123",
    name: "Christopher",
    falecimento: "2018-06-03"
  },
  {
    cod: "17731f29",
    name: "Travis",
    falecimento: "2022-04-28"
  },
  {
    cod: "87822617",
    name: "Patrick",
    falecimento: "2022-04-27"
  },
  {
    cod: "469ab53b",
    name: "Haley",
    falecimento: "2023-12-22"
  },
  {
    cod: "2ca7468f",
    name: "Anita",
    falecimento: "2018-10-22"
  }
]
const FakeAPI = {
  async fetch({ page, itemsPerPage, sortBy }) {
    return new Promise(resolve => {
      setTimeout(() => {
        const start = (page - 1) * itemsPerPage
        const end = start + itemsPerPage
        const items = posts.slice()

        if (sortBy.length) {
          const sortKey = sortBy[0].key
          const sortOrder = sortBy[0].order
          items.sort((a, b) => {
            const aValue = a[sortKey]
            const bValue = b[sortKey]
            return sortOrder === 'desc' ? bValue - aValue : aValue - bValue
          })
        }

        const paginated = items.slice(start, end)

        resolve({ items: paginated, total: items.length })
      }, 500)
    })
  },
}

export default {
  data: () => ({
    itemsPerPage: 5,
    headers: [
      {
        title: 'Código de Despedida',
        align: 'start',
        sortable: false,
        key: 'cod',
      },
      { title: 'Nome', key: 'name', align: 'start' },
      { title: 'Falecimento', key: 'falecimento', align: 'start' },
      { title: 'Ação', key: 'action', align: 'center' },
    ],
    search: '',
    serverItems: [],
    loading: true,
    totalItems: 0,
    page: 1,
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    }
  },
  methods: {
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true
      FakeAPI.fetch({ page, itemsPerPage, sortBy }).then(({ items, total }) => {
        this.serverItems = items
        this.totalItems = total
        this.loading = false
      })
    },
  },
}
</script>


<style scoped>

.container {
 min-height: 78vh;
}
.table-pai{
  margin-top: 4vh;
}

</style>