<template>
  <section>
    <h1>David Castro</h1>
    <div class="date">
      <div class="star">
        <img src="@/assets/images/icons/star.png" alt="Star icon">
      </div>
      <h2>15/02/1951</h2>
      <div class="cross">
        <img src="@/assets/images/icons/cross.png" alt="Cross icon">
      </div>
      <h2>01/08/2024</h2>
    </div>
  </section>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}

section {
  margin-top: 8rem;
}

h1 {
  text-align: center;
  color: #343F53;
  font-weight: 900;
  font-size: 50px;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.star, .cross {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 20px;
}

.cross {
  margin-left: 1rem;
}

h2 {
  text-align: center;
  color: #343F53;
  font-weight: 300;
  font-size: 20px;
}
</style>
